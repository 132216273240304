<template>
    <div class="container">
        <h1>Ubah Password</h1>

        <div class="form-wrapper">
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>Password Saat Ini</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0">
                        <v-text-field
                            v-model="form.current_password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            single-line solo dense outlined flat
                            required
                            :rules="[v => !!v || 'Password tidak boleh kosong']"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>Password Baru</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0">
                        <v-text-field
                            v-model="form.new_password"
                            single-line solo dense outlined flat
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            :rules="newPasswordRules"
                            required
                            @click:append="show2 = !show2"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>Konfirmasi Password Baru</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0">
                        <v-text-field
                            v-model="form.match_new_password"
                            single-line solo dense outlined flat
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            required
                            :rules="confirmPasswordRules"
                            :type="show3 ? 'text' : 'password'"
                            @click:append="show3 = !show3"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-alert dense outlined type="error" v-model="alert">
                {{this.message}}
            </v-alert>
            <v-btn class="primary-btn" @click="validate()">
                Simpan
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form:{
                current_password: '',
                new_password: '',
                match_new_password: ''
            },
            show1: false,
            show2: false,
            show3: false,
            message: '',
            alert: false,
            valid: false,
            load: false,
            newPasswordRules:[
                v => !!v || 'Password baru harus diisi',
                v => v.length >= 6 || 'Password minimal terdiri dari 6 karakter',
                v => /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/.test(v) || 'Password harus berisi huruf dan angka'
            ],
            confirmPasswordRules:[
                v => !!v || 'Konfirmasi password baru harap diisi',
                v => this.form.new_password === v || 'Password baru tidak sama'
            ]
        }    
    },
    methods:{
        async ubahPassword(){
            let newData = this.form
            let url = this.$api+'/ubah/password'

            try{
                const res = await this.$http.post(url, newData, {
                    withCredentials: true
                })

                this.message = res.message
                this.load = true
                this.$router.go(this.$router.currentRoute)
                this.alert = false
            }catch(e){
                this.message = e.response.data.message
                this.alert = true
            }

            this.load = false
        },
        async validate(){
            const res = await this.$refs.form.validate()
            if(res){
                this.ubahPassword()
            }
        }
    }
}
</script>