<template>
    <div>
        <v-app-bar
            flat
            dark
            max-height="55"
        >

            <v-app-bar-nav-icon
                @click.stop="toggle = !toggle"   
                class="hidden-md-and-up"
            ></v-app-bar-nav-icon>

            <v-toolbar-title>
                <h3>
                    Tes Minat Bakat
                </h3>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn
                    text
                    v-for="item in menu"
                    :key="item.path"
                    :to="item.path"
                >
                    {{item.name}}
                </v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <v-menu
            v-model="toggle"
        >
            <v-list>
                <v-list-item-group>
                    <v-list-item
                        v-for="item in menu"
                        :key="item.path"
                        :to="item.path"
                    >
                        <v-list-item-title>
                            {{item.name}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                toggle: false,
                menu: [
                    { name: 'Beranda', path: '/'},
                    { name: 'Tes', path: '/tes/formulir'},
                ]
            }
        }
    }
</script>
