<template>
    <div class="container">
        <v-row>
            <v-col cols="16" md="10" class="mb-0 pa-2">
                <h1>Kelola Daftar Pilihan</h1>
            </v-col>
            <v-col cols="16" md="2" class="mb-3 pt-3">
                <v-btn dense @click="dialogForm = true" class="primary-btn">Tambah</v-btn>
            </v-col>
        </v-row>
        <div class="form-wrapper">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
            ></v-text-field>
            <v-data-table
                :headers="headers"
                :items="daftarPertanyaanIndex"
                :search="search"
            >
                <template slot="daftarPernyataan" scope="props">
                    <td>{{props.index}}</td>
                    <td>{{props.kode}}</td>
                    <td>{{props.namaPilihan}}</td>
                    <td>{{props.aksi}}</td>
                </template>
                <template v-slot:[`item.aksi`]="{ item }">
                    <v-icon
                        @click="editHandler(item)"
                        color="blue"
                    >mdi-pencil</v-icon>
                    <v-icon
                        @click="deleteItem(item)"
                        color="red"
                    >mdi-delete</v-icon>
                    <v-icon
                        @click="showDetail(item)"
                        color="green"
                    >mdi-eye-outline</v-icon>
                </template>
            </v-data-table>
        </div>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="800"
            v-model="dialogForm"
            persistent
        >
            <v-card>
                <v-toolbar dark>          
                <v-btn
                    icon
                    dark
                    @click="dismiss"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ adding ? 'Tambah': 'Edit'}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-10">
                    <v-form v-model="valid" ref="form"> 
                        <v-row>
                            <v-col cols="12" md="4" class="mb-0 pa-0">
                                <v-subheader>
                                    Kode
                                </v-subheader>
                            </v-col>
                            <v-col cols="12" md="8" class="mt-0 pt-0">
                                <v-text-field
                                    :label="labels.kode"
                                    :rules="kodeRules"
                                    :counter="5"
                                    single-line solo dense outlined flat
                                    required
                                    v-model="form.kode"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" class="mb-0 pa-0">
                                <v-subheader>
                                    Nama Pilihan
                                </v-subheader>
                            </v-col>
                            <v-col cols="12" md="8" class="mt-0 pt-0">
                                <v-text-field
                                    :label="labels.namaPilihan"
                                    :rules="namaPilihanRules"
                                    :counter="20"
                                    single-line solo dense outlined flat
                                    required
                                    v-model="form.namaPilihan"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" class="mb-0 pa-0">
                                <v-subheader>
                                    Definisi
                                </v-subheader>
                            </v-col>
                            <v-col cols="12" md="8" class="mt-0 pt-0">
                                <v-textarea
                                    :label="labels.definisi"
                                    :counter="3000"
                                    :rules="definisiRules"
                                    single-line solo outlined flat
                                    required
                                    v-model="form.definisi"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" class="mb-0 pa-0">
                                <v-subheader>
                                    Penjelasan
                                </v-subheader>
                            </v-col>
                            <v-col cols="12" md="8" class="mt-0 pt-0">
                                <v-textarea
                                    :label="labels.penjelasan"
                                    :counter="3000"
                                    :rules="penjelasanRules"
                                    single-line solo outlined flat
                                    required
                                    v-model="form.penjelasan"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="primary-btn" @click="validate">
                        Simpan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="800"
            v-model="dialogDelete"
        >
            <v-card>
                <v-toolbar dark>
                    <v-btn icon dark @click="dialogDelete = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Hapus</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="text-h5 mt-5">
                    Yakin akan menghapus?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="blue" @click="dialogDelete = false">
                        Batal
                    </v-btn>
                    <v-btn text color="red" @click="deletePilihan()">
                        Ya
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogDetail"
            transition="dialog-bottom-transition"
            max-width="800"
            persistent
        >
            <v-card>
                <v-toolbar dark>
                    <v-btn icon dark @click="dialogDetail = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Detail</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-10">
                    <v-row>
                        <v-col cols="12" md="4" class="ma-0 pa-0">
                            <strong>Kode</strong>
                        </v-col>
                        <v-col cols="12" md="8" class="ma-0 pt-0">
                            {{detail.kode}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" class="ma-0 pa-0">
                            <strong>Nama Pilihan</strong>
                        </v-col>
                        <v-col cols="12" md="8" class="ma-0 pt-0">
                            {{detail.namaPilihan}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" class="ma-0 pa-0">
                            <strong>Definisi</strong>
                        </v-col>
                        <v-col cols="12" md="8" class="ma-0 pt-0">
                            {{detail.definisi}}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
export default {
    data(){
        return{
            search: null,
            dialogForm: false,
            dialogDelete: false,
            dialogDetail: false,
            adding: true,
            deleteId: '',
            arrPilihan:[],
            valid: false,
            message: null,
            pilihan: new FormData,
            headers:[
                {
                    text: 'No',
                    align: 'start',
                    value: 'index'
                },
                {
                    text: 'Kode',
                    value: 'kode'
                },
                {
                    text: 'Nama Pilihan',
                    value: 'namaPilihan'
                },
                {
                    text: '',
                    value: 'aksi'
                }
            ],
            detail:{
                kode: '',
                namaPilihan: '',
                definisi: '',
            },
            form:{
                id_pilihan: '',
                kode: '',
                namaPilihan: '',
                definisi: '',
                penjelasan: ''
            },
            initForm:{
                id_pilihan: '',
                kode: '',
                namaPilihan: '',
                definisi: '',
                penjelasan: ''     
            },
            labels:{
                kode: 'Kode pilihan. Contoh: ANA.',
                namaPilihan: 'Kepanjangan dari kode. Misalnya ANA untuk Analyst.',
                definisi: 'Definisi dari pilihan yang dibuat, mis. definisi Analyst.',
                penjelasan: 'Ciri-ciri yg ada pada seseorang mengenai pilihan yg dibuat.'
            },
            kodeRules:[
                v => !!v || 'Kode harus diisi',
                v => v.length <= 5 || 'Kode tidak boleh lebih dari 5 huruf'
            ],
            namaPilihanRules:[
                v => !!v || 'Nama pilihan harus diisi',
                v => v.length <= 20 || 'Nama pilihan tidak boleh lebih dari 20 kata '
            ],
            definisiRules:[
                v => !!v || 'Definisi tidak boleh kosong',
                v => v.length <= 3000 || 'Definisi tidak boleh lebih dari 3000 kata'
            ],
            penjelasanRules:[
                v => !!v || 'Penjelasan tidak boleh kosong',
                v => v.length <= 3000 || 'Penjelasan tidak boleh lebih dari 3000 kata'
            ]
        }
    },
    methods:{
        async save(){
            this.pilihan.append('kode', this.form.kode)
            this.pilihan.append('namaPilihan', this.form.namaPilihan)
            this.pilihan.append('definisi', this.form.definisi)
            this.pilihan.append('penjelasan', this.form.penjelasan)

            let url = this.$api + "/pilihan/create"
            try{
                const res = await this.$http.post(url, this.pilihan, {
                    withCredentials: true
                })
                this.message = res.data.message
                this.getDaftarPilihan()
                this.dismiss()
            }catch(e){
                this.message = e.message
            }
        },
        async getDaftarPilihan(){
            let url = this.$api+'/pilihan/read'
            const res = await this.$http.get(url, {
                withCredentials: true
            })

            try{
                this.arrPilihan = res.data.data
            }catch(e){
                this.message = e.message
            }
        },
        async update(){
            let newData = this.form
            try{
                let url = this.$api+'/pilihan/update'
                const res = await this.$http.post(url, newData, {
                    withCredentials: true
                })
                this.message = res.data.message
                this.getDaftarPilihan()
                this.dismiss()
            }catch(e){
                this.message = e.message
            }

        },
        async deletePilihan(){
            let url = this.$api + '/pilihan/delete/'+this.deleteId
            const res = await this.$http.delete(url,{
                withCredentials: true
            })

            try{
                this.message = res.data.message
                this.getDaftarPilihan()
                this.dialogDelete = false
            }catch(e){
                this.message = e.message
            }
        },
        showDetail(item){
            this.dialogDetail = true
            this.detail.kode = item.kode
            this.detail.namaPilihan = item.namaPilihan
            this.detail.definisi = item.definisi
        },
        //UI LOGIC
        editHandler(item){
            this.adding = false
            this.dialogForm = true
            this.form.id_pilihan = item.id_pilihan
            this.form.kode = item.kode
            this.form.namaPilihan = item.namaPilihan
            this.form.definisi = item.definisi
            this.form.penjelasan = item.penjelasan
        },
        deleteItem(item){
            this.dialogDelete = true
            this.deleteId = item.id_pilihan
        },
        dismiss(){
            this.adding = true
            this.dialogForm = false
            this.valid = false
            this.form = { 
                ...this.initForm
            }
        },
        formHandler(){
            if(this.adding){
                this.save()
            }else{
                this.update()
            }
        },
        async validate(){
            const res = await this.$refs.form.validate()
            if(res){
                this.formHandler()
            }
        }

    },
    computed:{
        daftarPertanyaanIndex(){
            return this.arrPilihan.map(
                (arrPilihan, index) => ({
                ...arrPilihan,
                index: index + 1
            }))
        }
    },
    mounted(){
        this.getDaftarPilihan()
    }
}
</script>

