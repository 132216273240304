<template>
    <div class="container">
        <div class="title-wrapper">
            <h1>Pernyataan yang Tidak Cocok</h1>
            <p>Silahkan memilih 5-7 pernyataan yang paling tidak sesuai dengan diri anda</p>
        </div>
        <div class="statements-wrapper">
            <v-progress-linear
                indeterminate
                style="margin: 20px;"
                color="#4B6587"
                v-show="progress"
            ></v-progress-linear>
            <div
                v-for="q in choices"
                :key="q.id_pilihan"
            >
                <v-checkbox
                    class="mt-0 pa-0 my-checkbox"
                    :label="q.definisi"
                    @change="checked(q)"
                    v-model="q.selected"
                ></v-checkbox>
            </div>
            <v-alert
                v-model="alertBox"
                dense outlined type="error"
            >
                {{alertMessage}}
            </v-alert>
            <v-btn
                @click="validation()"
                class="primary-btn"
            >
                Lanjut
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            statements:[],
            selectedStatements:[],
            storedStatements:[],
            choices: [],
            alertBox: false,
            progress: true,
            alertMessage: '',
            user: {
                'id_peserta': '',
                'id_tes': ''
            },
            listDetail: {
                DetailPilihanPalingCocok: null,
                DetailPilihanPalingTidakCocok: null,
                DetailPilihanCocok: null,
                DetailPilihanTidakCocok: null
            }, 
            palingCocok: [],
            palingTidakCocok: [],
            cocok: [],
            tidakCocok: [],
            model: 'DetailPilihanTidakCocok',
            arrDetail: {},
            peserta: new FormData,
            message: '',
            id_tes: '',
            
        }
    },
    methods:{
        checked(item){
            if(item.selected){
                this.selectedStatements.push(item)
            }else{
                let position = this.selectedStatements.findIndex(el=>el.pernyataan === item.pernyataan)
                this.selectedStatements.splice(position, 1)
            }
        },
        async getPilihan(){
            let url = this.$api + '/peserta/pilihan/read'
            const res = await this.$http.get(url, {
                withCredentials: true
            })

            this.progress = false

            if(sessionStorage.getItem('DetailPilihanCocok') == null){
                alert('Harap melakukan tes tahap sebelumnya!')
                this.$router.back()
            }else if(sessionStorage.getItem('isDone') != null){
                alert('Tes sudah selesai dilakukan!')
                this.$router.push('/tes/hasil')
            }

            this.statements = await res.data.data
            this.getStoredStatements()
        },
        async getStoredStatements(){
            this.palingCocok = JSON.parse(sessionStorage.getItem('DetailPilihanPalingCocok'))
            this.palingTidakCocok = JSON.parse(sessionStorage.getItem('DetailPilihanPalingTidakCocok'))
            this.cocok = JSON.parse(sessionStorage.getItem('DetailPilihanCocok'))

            this.storedStatements = this.palingCocok
            this.storedStatements = await this.storedStatements.concat(this.palingTidakCocok)
            this.storedStatements = await this.storedStatements.concat(this.cocok)

            this.compare()
        },
        async storeArrayToStorage(){
            sessionStorage['DetailPilihanTidakCocok'] = JSON.stringify(this.selectedStatements)
            
            this.makeDetailList()
        },
        makeDetailList(){
            this.tidakCocok = JSON.parse(sessionStorage.getItem('DetailPilihanTidakCocok'))

            this.listDetail.DetailPilihanPalingCocok = this.palingCocok
            this.listDetail.DetailPilihanPalingTidakCocok = this.palingTidakCocok
            this.listDetail.DetailPilihanCocok = this.cocok
            this.listDetail.DetailPilihanTidakCocok = this.tidakCocok

            console.log(this.listDetail)
            this.pushDataPeserta()
        },
        async pushDataPeserta(){
            let url = this.$api + '/peserta/create'

            this.peserta = JSON.parse(sessionStorage.getItem('peserta'))
            console.log(this.peserta)

            
            await this.$http.post(url, this.peserta, {
                withCredentials: true
            }).then(response => {
                this.message = response.data.message
                this.id_tes = response.data.id_tes
                sessionStorage['id_tes'] = this.id_tes
                sessionStorage['isDone'] = true
                this.pushDetailPilihan()
            }).catch(error => {
                this.message = error.response.data.message
            })
        },
        async pushDetailPilihan(){
            let url = this.$api + '/tes/create/detail/'+this.id_tes

            await this.$http.post(url, this.listDetail, {
                withCredentials: true
            }).then(response => {
                this.message = response.data.message
                this.$router.push('/tes/hasil')
            })
        },
        compare(){
            const statements2ID = new Set(this.storedStatements.map(({ id_pilihan }) => id_pilihan));
            this.choices = this.statements.filter(( { id_pilihan }) => !statements2ID.has(id_pilihan))    
        },
        async validation(){
            if(this.selectedStatements.length > 7 || this.selectedStatements.length < 5){
                this.alertBox = true
                this.alertMessage = 'Harap memilih 5-7 pernyataan'
            }else{
                this.alertBox = false
                await this.storeArrayToStorage()
                
            }
        },
    },
    mounted(){
        this.getPilihan()
    }
}
</script>
