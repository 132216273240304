<template>
    <div class="container" id="print">
        <div class="title-wrapper">
            <h1>Hasil Tes</h1>
            <h2>{{user.nama}}</h2>
        </div>
        <div class="result-wrapper" id="talent-map">
            <svg id="myicon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 720 900">
                <image id="gambar" width="720" height="900" xlink:href="../assets/talent_map.jpg"></image>
                <g
                    v-for="c in daftarPilihan"
                    v-bind:key="c.id_pilihan"    
                    @mouseover="show(c, $event)"  
                    @mouseleave="hide()" 
                >
                    <circle
                        v-bind:cx="c.cx"
                        v-bind:cy="c.cy"
                        v-bind:r="c.r"
                        v-bind:fill="c.fill"
                        opacity="0.5"
                    >
                    </circle>
                </g>
            </svg>

            <v-btn class="primary-btn" @click="printHasil">
                Download
            </v-btn>
        </div>

        <div class="caption-wrapper" id="content">
            <p>
                <strong>{{user.nama}}, </strong> - anda adalah orang yang {{ hasil }}
            </p>

            <h3 class="text-decoration-underline">Penjelasan Warna</h3>
            <div 
                v-for="k in keterangan"
                v-bind:key="k.no"
            >
                <p>
                    <v-icon
                       :color="k.warna" 
                    >mdi-circle</v-icon>
                    {{ k.penjelasan }}
                </p>
            </div>

            <v-row>
                <v-col cols="12" md="4">
                    Potensi Kelebihan Anda: 
                    <ul v-for="i in arrPalingCocok" :key="i.id_pilihan">
                        <li>
                            {{ i.kode }} - {{ i.namaPilihan }}
                        </li>
                    </ul>
                </v-col>
                <v-col cols="12" md="4">
                    Potensi Kekurangan Anda:
                    <ul v-for="i in arrPalingTidakCocok" :key="i.id_pilihan">
                        <li>
                            {{ i.kode }} - {{ i.namaPilihan }}
                        </li>
                    </ul>
                </v-col>
            </v-row>

            <h3 class="text-decoration-underline">Penjelasan 8 Kelompok Besar Tipologi</h3>
            <div v-for="i in typologies" :key="i.title">
                <strong>{{ i.title }}</strong> <br>
                {{ i.penjelasan }}
            </div>

            <h3 class="text-decoration-underline">Definisi Setiap Tipologi</h3>
            <div v-for="i in arrPilihan" :key="i.namaPilihan">
                <v-card class="mx-auto" outlined>
                    <v-card-title>{{ i.kode }} | {{ i.namaPilihan }}</v-card-title>
                    <v-card-text>{{ i.definisi }}</v-card-text>
                </v-card>
            </div>
        </div>

        <div id="custom-tooltip"></div>
    </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import jsPDF from "jspdf"
import html2canvas from 'html2canvas'
import 'svg2pdf.js'
import canvg from 'canvg'

export default {
    data(){
        return{
            popup: false,
            color: 'blue',
            circles:[],
            peserta: {},
            daftarPilihan: [],
            arrPilihan: [],
            arrPilihanPDF: [],
            arrPalingCocok: [],
            arrPalingTidakCocok: [],
            user:{},
            tes:{},
            id_tes: '',
            hasil: '',
            potensiKelebihan: [],
            potensiKekurangan: [],
            seen: false,
            keterangan: [
                {
                    no: 1,
                    warna: "#e8170c",
                    penjelasan: 'Sangat cocok dengan diri anda'
                },
                {
                    no: 2,
                    warna: "#454541",
                    penjelasan: "Sangat tidak cocok dengan diri anda"
                },
                {
                    no: 3,
                    warna: "#e8e80c",
                    penjelasan: "Cocok dengan diri anda"
                },
                {
                    no: 4,
                    warna: "#d1d1cd",
                    penjelasan: "Tidak cocok dengan diri anda"
                }
            ],
            typologies: [
                {
                    title: 'H (Headman)',
                    penjelasan: 'Aktivitas berinteraksi dengan orang lain dalam rangka mengendalikan dan mempengaruhi'
                },
                {
                    title: 'N (Networking)',
                    penjelasan: 'Aktivitas berinteraksi dengan orang lain dalam rangka bekerja sama',
                },
                {
                    title: 'S (Serving)',
                    penjelasan: 'Aktivitas berinteraksi dengan orang lain dalam rangka merawat / melayani'
                },
                {
                    title: 'Gi (Generating Idea)',
                    penjelasan: 'Aktivitas individual menggunakan pemikiran intuitif / kreatif',
                },
                {
                    title: 'T (Thinking)',
                    penjelasan: 'Aktivitas individual menggunakan pemikiran logika'
                },
                {
                    title: 'R (Reasoning)',
                    penjelasan: 'Aktivitas individual menggunakan logika untuk mencari atau membuktikan sesuatu'
                },
                {
                    title: 'E (Elementary)',
                    penjelasan: 'Aktivitas individual yang tidak perlu banyak berpikir dan di dalam ruangan'
                },
                {
                    title: 'Te (Technical)',
                    penjelasan: 'Aktivitas individual yang tidak perlu banyak berpikir dan di luar ruangan'
                }
            ]
        }
    },
    methods:{
        show(c, event){
            let tooltip = document.getElementById("custom-tooltip")
            tooltip.innerText = c.definisi
            tooltip.style.display = "block"
            tooltip.style.left = event.pageX + 'px'
            tooltip.style.top = event.pageY + 'px'
        },
        hide(){
            let tooltip = document.getElementById("custom-tooltip")
            tooltip.style.display = "none"
        },
        async retrieveHasilData(){
            this.id_tes = await sessionStorage.getItem('id_tes')
            this.user = await JSON.parse(sessionStorage.getItem('peserta'))
            console.log(this.user.nama)

            this.getHasilTes()
        },
        async getHasilTes(){
            let url = this.$api +'/tes/hasil/'+this.id_tes
            const res = await this.$http.get(url, {
                withCredentials: true
            })

            this.peserta = await res.data.peserta
            this.arrPalingCocok = await res.data.palingCocok
            this.arrPalingTidakCocok = await res.data.palingTidakCocok

            this.daftarPilihan = await this.daftarPilihan.concat(this.arrPalingCocok)
            this.daftarPilihan = await this.daftarPilihan.concat(this.arrPalingTidakCocok)
            this.daftarPilihan = await this.daftarPilihan.concat(res.data.cocok)
            this.daftarPilihan = await this.daftarPilihan.concat(res.data.tidakCocok)

            this.potensiKelebihan = await res.data.kelebihan
            this.potensiKekurangan = await res.data.kekurangan

            this.hasil = await res.data.result
        },
        async getPilihans(){
            if(sessionStorage.getItem('isDone') != null){
                let url = this.$api + '/peserta/pilihan/read'
                const res = await this.$http.get(url, {
                    withCredentials: true
                })

                this.arrPilihan = await res.data.data
                this.arrPilihanPDF = await res.data.dataPDF
            }else{
                alert('Harap menyelesaikan tes terlebih dahulu!')
                this.$router.go(-1)
            }

        },
        printHasil(){
            var doc = new jsPDF('p', 'pt', 'a4')

            const element = document.getElementById('myicon')

            let x = 50;
            let y = 15;
            let width = 500;
            let height = 700;

            let headers1 = ['kode', 'namaPilihan']
            let headers2 = ['kode', 'namaPilihan', 'definisi']
            let headers3 = ['title', 'penjelasan']

            doc.text('Hasil Tes: '+this.user.nama, 300, 40, { align: 'center' })
            doc.svg(element, {x, y, width, height}).then(() => {
                doc.setFontSize(12)
                doc.text(this.user.nama + ', anda adalah orang yang ' +this.hasil, 50, 700, {maxWidth: 500, align: 'justify'})

                doc.addPage()

                doc.text('Keterangan Warna: ', 50, 50)
                doc.text('Merah - sangat cocok dengan diri anda', 50, 65)
                doc.text('Hitam - sangat tidak cocok dengan diri anda', 50, 80)
                doc.text('Kuning - cocok dengan diri anda', 50, 95)
                doc.text('Abu-abu - tidak cocok dengan diri anda', 50, 110)
                
                doc.text('Potensi Kelebihan Anda', 50, 140)
                doc.table(50, 150, this.potensiKelebihan, headers1)
                doc.text('Potensi Kekurangan Anda', 300, 140)
                doc.table(300, 150, this.potensiKekurangan, headers1)

                doc.text('Penjelasan Tipologi', 50, 390)
                doc.table(50, 400, this.typologies, headers3, { margins: (5, 5, 5, 5), autoSize: true, fontSize: 10 })

                doc.addPage()
                doc.text('Definisi Setiap Tipologi', 50, 50)
                doc.table(50, 60, this.arrPilihanPDF, headers2, { autoSize: true, fontSize: 8.5 })

                doc.save('hasil_'+this.user.nama +'.pdf')
            })
        
        }
    },
    mounted(){
        this.getPilihans()
        this.retrieveHasilData()
    },
    component:{
        html2pdf, jsPDF, html2canvas, canvg
    },
}
</script>
<style scoped lang="scss">
    .result-wrapper{
        text-align: center;
        padding-bottom: 5rem;
    }

    .caption-wrapper{
        text-align: justify;
        padding: 1rem 3rem 1rem 3rem;
        font-size: 20px;
    }
    #custom-tooltip {
        position: absolute;
        background: #F6EABE;
        box-shadow: #34626C 2px 2px 2px;
        padding: 6px;
        border-radius: 8px;
        max-width: 180px;
        text-align: justify;
    }

    .text-decoration-underline{
        margin-top: 30px;
    }

</style>