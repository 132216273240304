import Vue from 'vue'
import VueRouter from 'vue-router'
import Beranda from '../views/Beranda.vue'
import UserLayout from '@/components/UserLayout.vue'
import DataDiri from '../views/DataDiri.vue'
import TesPalingCocok from '../views/TesPalingCocok.vue'
import TesPalingTidakCocok from '../views/TesPalingTidakCocok.vue'
import TesCocok from '../views/TesCocok.vue'
import TesTidakCocok from '../views/TesTidakCocok.vue'
import HasilTes from '../views/HasilTes.vue'
import LoginAdmin from '../views/Admin/Login.vue'
import DrawerAdmin from '../components/AdminLayout.vue'
import KelolaPilihan from '../views/Admin/KelolaPilihan.vue'
import KelolaKonten from '../views/Admin/KelolaKonten.vue'
import HasilPencarian from '../views/HasilPencarianTes.vue'
import UbahPassword from '../views/Admin/UbahPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: UserLayout,
    children:[
      {
        path: '/',
        component: Beranda,
        name: 'Beranda'
      },
      {
        path: '/tes/formulir',
        component: DataDiri,
        name: 'Data Diri',
      },
      {
        path: '/tes/palingcocok',
        component: TesPalingCocok,
        name: 'Paling Cocok',
        meta: {Auth: true}
      },
      {
        path: '/tes/palingtidakcocok',
        component: TesPalingTidakCocok,
        name: 'Paling Tidak Cocok',
        meta: {Auth: true}
      },
      {
        path: '/tes/cocok',
        component: TesCocok,
        name: 'Cocok',
        meta: {Auth: true}
      },
      {
        path: '/tes/tidakcocok',
        component: TesTidakCocok,
        name: 'Tidak Cocok',
        meta: {Auth: true}
      },
      {
        path: '/tes/hasil',
        component: HasilTes,
        name: 'Hasil',
        meta: {Auth: true}
      },
      {
        path: '/tes/cari/:email',
        component: HasilPencarian,
        name: 'Hasil Pencarian'
      }
    ],
  },
  {
    path: '/admin/login',
    component: LoginAdmin,
    name: 'Login Admin',
    meta: { disallowAuthed: true}
  },
  {
    path: '/kelola/pernyataan',
    component: DrawerAdmin,
    meta: {Auth: true},
    children:[
      {
        path: '/kelola/pilihan',
        component: KelolaPilihan,
        name: 'Kelola Pilihan'
      },
      {
        path: '/kelola/konten',
        component: KelolaKonten,
        name: 'Kelola Konten'
      },
      {
        path: '/ubah/password',
        component: UbahPassword,
        name: 'Ubah Password'
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.Auth)) {
      if (sessionStorage.getItem('isLoggedIn')) {
        document.title = `${ process.env.VUE_APP_TITLE } - ${ to.name }` 
        next();
      }else {
        router.go(-1)
      }
  } else if(to.matched.some(record => record.meta.disallowAuthed)){
      if(sessionStorage.getItem('isLoggedIn') != null){
        router.go(-1)
      }else{
        document.title = `${ process.env.VUE_APP_TITLE } - ${ to.name }` 
        next()
      }
  } else {
      document.title = `${ process.env.VUE_APP_TITLE } - ${ to.name }` 
      next()
  }
});