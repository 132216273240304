<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components:{
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import '@/assets/main.scss';
  html, body{
    background: red;
    width: 100%;
    height: 100%;
  }
</style>
