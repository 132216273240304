<template>
    <div class="container">
        <div class="title-wrapper">
            <h1>Pernyataan yang Paling Cocok</h1>
            <p>Silahkan memilih 5-7 pernyataan yang paling sesuai dengan diri anda</p>
        </div>
        <div class="statements-wrapper">
            <v-progress-linear
                indeterminate
                style="margin: 20px;"
                color="#4B6587"
                v-show="progress"
            ></v-progress-linear>
            <div
                v-for="q in statements"
                :key="q.id_pilihan"
            >
                <v-checkbox
                    class="mt-0 pa-0 my-checkbox justify"
                    :label="q.definisi"
                    @change="checked(q)"
                    v-model="q.selected"
                ></v-checkbox>
            </div>
            <v-alert
                v-model="alertBox"
                dense outlined type="error"
            >
                {{alertMessage}}
            </v-alert>
            <v-btn
                @click="validation()"
                class="primary-btn"
            >
                Lanjut
            </v-btn>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return{
            statements:[],
            selectedStatements:[],
            alertBox: false,
            alertMessage: '',
            progress: true,
            user: {
                'id_peserta': '',
                'id_tes': ''
            },
            id_tes: '',
            model: 'DetailPilihanPalingCocok',
            arrDetail: {},
            listData2: {
                palingCocok: null,
                palingTidakCocok: null,
            },
            palingTidakCocok: [
                {
                    id_pilihan: 3,
                    namaPilihan: "Educator"
                },
                {
                    id_pilihan: 4,
                    namaPilihan: "Visionary"
                }
            ],
            palingCocok: [
                {
                    id_pilihan: 1,
                    namaPilihan: "Analyst",
                },
                {
                    id_pilihan: 2,
                    namaPilihan: "Ambassador"
                }
            ],
        }
    },
    components:{

    },
    methods:{
        async getDaftarPilihan(){
            let url = this.$api + '/peserta/pilihan/read'
            const res = await this.$http.get(url, {
                withCredentials: true
            })

            try{
                this.statements = res.data.data
                this.progress = false

                if(sessionStorage.getItem('isDone') != null){
                    alert('Tes sudah selesai dilakukan!')
                    this.$router.push('/tes/hasil')
                }
            }catch(e){
                console.log(e.message)
            }
        },
        async createPilihan(){
            sessionStorage['DetailPilihanPalingCocok'] = JSON.stringify(this.selectedStatements)
        },  
        checked(item){
            if(item.selected){
                this.selectedStatements.push(item)
            }else{
                let position = this.selectedStatements.findIndex(el=>el.pernyataan === item.pernyataan)
                this.selectedStatements.splice(position, 1)
            }
            
        },
        async validation(){
            if(this.selectedStatements.length > 7 || this.selectedStatements.length < 5){
                this.alertBox = true
                this.alertMessage = 'Harap memilih 5-7 pernyataan'
            }else{
                this.alertBox = false
                await this.createPilihan()
                console.log('masuk')
                this.$router.push('/tes/palingtidakcocok')
            }
        },
    },
    mounted(){
        this.getDaftarPilihan()
    }
}
</script>
<style scoped lang="scss">

</style>