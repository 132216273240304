<template>
    <div class="container">
        <v-row>
            <v-col cols="16" md="10" class="mb-0 pa-2">
                <h1>Kelola Konten</h1>
            </v-col>
            <v-col cols="16" md="2" class="mb-3 pt-3">
                <v-btn dense @click="validate" class="primary-btn">Ubah</v-btn>
            </v-col>
        </v-row>
        <div class="form-wrapper">
            <v-form
                v-model="valid"
                ref="form"
            >
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>
                            Judul
                        </v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0">
                        <v-text-field
                            label="Judul"
                            :counter="30"
                            v-model="form.judul"
                            single-line solo dense outlined flat
                            required
                            :rules="judulRules"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>
                            Isi
                        </v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0">
                        <v-textarea
                            label="Isi"
                            :rules="isiRules"
                            v-model="form.isi_konten"
                            :counter="2000"
                            single-line solo dense outlined flat
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return{
            valid: false,
            message: '',
            form:{
                judul: '',
                isi_konten: ''
            },
            judulRules:[
                v => !!v || 'Judul tidak boleh kosong',
                v => v.length <= 30 || 'Judul tidak boleh lebih dari 30 kata'
            ],
            isiRules:[
                v => !!v || 'Isi tidak boleh kosong',
                v => v.length <= 2000 || 'Isi tidak boleh lebih dari 2000 kata'
            ]
        }
    },
    methods:{
        async validate(){
            const res = await this.$refs.form.validate()

            if(res){
                this.update()
            }
        },
        getKonten(){
            let url = this.$api + '/konten/tampil'
            
            this.$http.get(url, {
                withCredentials: true
            }).then(response => {
                this.form.judul = response.data.data.judul
                this.form.isi_konten = response.data.data.isi_konten
            })
        },
        async update(){
            let newData = {
                judul: this.form.judul,
                isi_konten: this.form.isi_konten
            }
            let url = this.$api + '/konten/update'
            
            const res = await this.$http.put(url, newData, {
                withCredentials: true
            })
            this.message = res.data.message
        }

    },
    mounted(){
        this.getKonten()
    }
}
</script>
<style scoped>
    .v-subheader{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }
</style>