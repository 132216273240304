<template>
    <div>
        <Drawer/>
        <v-main>
            <router-view></router-view>
        </v-main>
    </div>
</template>
<script>
import Drawer from './AdminDrawer.vue'
export default {
    data(){
        return{

        }
    },
    components:{
        Drawer
    }
}
</script>