<template>
    <v-container>
        <v-footer
            :padless="true"
            absolute
        >
            <v-card
                flat
                tile
                width="100%"
                class="white--text text-center"
                color="transparent"
            >
                <v-card-text class="white--text">
                    <strong>Griya Media Nusantara</strong> | {{ new Date().getFullYear() }}  
                </v-card-text>
                <v-card-text>
                    <v-btn
                        v-for="b in buttons"
                        :key="b.id"
                        color="white"
                        icon
                        v-bind:href="b.ref"
                        target="_blank"
                    >
                        <v-icon>{{b.icon}}</v-icon>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            buttons:[
                {
                    id: 1,
                    icon: 'mdi-instagram',
                    ref: 'https://www.instagram.com/griyawebsite/?hl=id'
                },
                {
                    id: 2,
                    icon: 'mdi-phone-outline',
                    ref: 'https://t.me/joinchat/RiB2NobZSacZvXQA'
                },
                {
                    id: 3,
                    icon: 'mdi-web',
                    ref: 'https://www.griyawebsite.com/'
                }
            ]
        }

    }
}
</script>

