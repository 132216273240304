<template>
    <div class="container">
        <div class="title-wrapper">
            <h1>Pernyataan yang Paling Tidak Cocok</h1>
            <p>Silahkan memilih 5-7 pernyataan yang paling tidak sesuai dengan diri anda</p>
        </div>
        <div class="statements-wrapper">
            <v-progress-linear
                indeterminate
                style="margin: 20px;"
                color="#4B6587"
                v-show="progress"
            ></v-progress-linear>
            <div
                v-for="q in choices"
                :key="q.id_pilihan"
            >
                <v-checkbox
                    class="mt-0 pa-0 my-checkbox"
                    :label="q.definisi"
                    @change="checked(q)"
                    v-model="q.selected"
                ></v-checkbox>
            </div>
            <v-alert
                v-model="alertBox"
                dense outlined type="error"
            >
                {{alertMessage}}
            </v-alert>
            <v-btn
                @click="validation()"
                class="primary-btn"
            >
                Lanjut
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            statements:[],
            selectedStatements:[],
            storedStatements:[],
            choices: [],
            alertBox: false,
            progress: true,
            alertMessage: '',
            user: {
                'id_peserta': '',
                'id_tes': ''
            },
            model: 'DetailPilihanPalingTidakCocok',
            arrDetail: {}
        }
    },
    methods:{
        checked(item){
            if(item.selected){
                this.selectedStatements.push(item)
            }else{
                let position = this.selectedStatements.findIndex(el=>el.pernyataan === item.pernyataan)
                this.selectedStatements.splice(position, 1)
            }
        },
        async getDaftarPilihan(){
            let url = this.$api + '/peserta/pilihan/read'
            const res = await this.$http.get(url, {
                withCredentials: true
            })

            try{
                this.statements = await res.data.data
                this.progress = false
                this.getDaftarPalingCocok()

                if(sessionStorage.getItem('DetailPilihanPalingCocok') == null){
                    alert('Harap melakukan tes tahap sebelumnya!')
                    this.$router.back()
                }else if(sessionStorage.getItem('isDone') != null){
                    alert('Tes sudah selesai dilakukan!')
                    this.$router.push('/tes/hasil')
                }
            }catch(e){
                console.log(e)
            }
        },  
        async getDaftarPalingCocok(){
            var arr = sessionStorage.getItem('DetailPilihanPalingCocok') 
            this.storedStatements = JSON.parse(arr)
            this.compare()
        }, 
        async createPalingTidakCocok(){
            sessionStorage['DetailPilihanPalingTidakCocok'] = JSON.stringify(this.selectedStatements)
        }, 
        async compare(){
            const statements2ID = new Set(this.storedStatements.map(({ id_pilihan }) => id_pilihan));
            this.choices = this.statements.filter(( { id_pilihan }) => !statements2ID.has(id_pilihan))   
        },
        async validation(){
            if(this.selectedStatements.length > 7 || this.selectedStatements.length < 5){
                this.alertBox = true
                this.alertMessage = 'Harap memilih 5-7 pernyataan'
            }else{
                this.alertBox = false
                await this.createPalingTidakCocok()
                this.$router.push('/tes/cocok')
            }
        }
    },
    mounted(){
        this.getDaftarPilihan()
    }
}
</script>