<template>
    <div class="container">
        <div class="title-wrapper">
            <h1>Pernyataan yang Cocok</h1>
            <p>Silahkan memilih 5-7 pernyataan yang paling tidak sesuai dengan diri anda</p>
        </div>
        <div class="statements-wrapper">
            <v-progress-linear
                indeterminate
                style="margin: 20px;"
                color="#4B6587"
                v-show="progress"
            ></v-progress-linear>
            <div
                v-for="q in choices"
                :key="q.id_pilihan"
            >
                <v-checkbox
                    class="mt-0 pa-0 my-checkbox"
                    :label="q.definisi"
                    @change="checked(q)"
                    v-model="q.selected"
                ></v-checkbox>
            </div>
            <v-alert
                v-model="alertBox"
                dense outlined type="error"
            >
                {{alertMessage}}
            </v-alert>
            <v-btn
                @click="validation()"
                class="primary-btn"
            >
                Lanjut
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            statements:[],
            selectedStatements:[],
            storedStatements:[],
            choices: [],
            alertBox: false,
            progress: true,
            alertMessage: '',
            user: {
                'id_peserta': '',
                'id_tes': ''
            },
            model: 'DetailPilihanCocok',
            arrDetail: {},
            arrPalingCocok: [],
            arrPalingTidakCocok: []
        }
    },
    methods:{
        async getDaftarPilihan(){
            let url = this.$api + '/peserta/pilihan/read'
            const res = await this.$http.get(url, {
                withCredentials: true
            })

            this.statements = res.data.data
            this.progress = false

            if(sessionStorage.getItem('DetailPilihanPalingTidakCocok') == null){
                alert('Harap melakukan tes tahap sebelumnya!')
                this.$router.back()
            }else if(sessionStorage.getItem('isDone') != null){
                alert('Tes sudah selesai dilakukan!')
                this.$router.push('/tes/hasil')
            }

            this.getDaftarStoredPilihan()
        },
        async getDaftarStoredPilihan(){
            var arr1 = JSON.parse(sessionStorage.getItem('DetailPilihanPalingCocok'))
            var arr2 = JSON.parse(sessionStorage.getItem('DetailPilihanPalingTidakCocok'))

            this.storedStatements = arr1
            this.storedStatements = await this.storedStatements.concat(arr2)

            this.compare()
        },
        async createPilihanCocok(){
            sessionStorage['DetailPilihanCocok'] = JSON.stringify(this.selectedStatements)
        },
        checked(item){
            if(item.selected){
                this.selectedStatements.push(item)
            }else{
                let position = this.selectedStatements.findIndex(el=>el.pernyataan === item.pernyataan)
                this.selectedStatements.splice(position, 1)
            }
        },
        async compare(){
            const statements2ID = new Set(this.storedStatements.map(({ id_pilihan }) => id_pilihan));
            this.choices = this.statements.filter(( { id_pilihan }) => !statements2ID.has(id_pilihan)) 
        },
        async validation(){
            if(this.selectedStatements.length > 7 || this.selectedStatements.length < 5){
                this.alertBox = true
                this.alertMessage = 'Harap memilih 5-7 pernyataan'
            }else{
                this.alertBox = false
                await this.createPilihanCocok()
                this.$router.push('/tes/tidakcocok')
            }
        }
    },
    mounted(){
        this.getDaftarPilihan()
    }
}
</script>
