<template>
    <div class="login-container fill-height background" >
        <h1>Login Admin Panel</h1>
        <div class="form-wrapper mt-3">
            <v-form v-model="valid" ref="form">
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>Username</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0">
                        <v-text-field
                            label="Username"
                            :rules="usernameRules"
                            single-line solo dense outlined flat
                            v-model="form.username"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" class="mb-0 pa-0">
                        <v-subheader>Password</v-subheader>
                    </v-col>
                    <v-col cols="12" md="8" class="mt-0 pt-0"> 
                        <v-text-field
                            label="Password"
                            :rules="passwordRules"
                            type="password"
                            single-line solo dense outlined flat
                            v-model="form.password"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-alert
                    v-model="alert"
                    dense outlined type="error"
                >
                    {{ error_message }}
                </v-alert>

                <v-row>
                    <v-col cols="12" md="12">
                        <v-btn class="primary-btn" @click="submit">Login</v-btn>
                    </v-col>
                </v-row>
            </v-form>

        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            form:{
                username: '',
                password: '',
            },
            message: '',
            load: false,
            valid: false,
            usernameRules:[
                v => !!v || 'Username harus diisi'
            ],
            passwordRules:[
                v => !!v || 'Password harus diisi'
            ],
            alert: false,
            error_message: ''
        }
    },
    methods:{
        async submit(){
            try{
                const res = await this.$refs.form.validate()
                const url = this.$api + '/admin/login'
                this.load = true
                if(!res){
                    return;
                }else{  
                    const response = await this.$http.post(url, {
                        username: this.form.username,
                        password: this.form.password
                    }, {
                        withCredentials: true
                    })
                    sessionStorage.setItem('isLoggedIn', true)
                    this.$router.push({
                        name: 'Kelola Pilihan'
                    })
                    this.message = response.data
                }
            }catch(e){
                console.log(e)
            }
        },
        clear(){
            this.$refs.form.reset()
        },
    }
}
</script>
<style scoped lang="scss">
    .login-container{
        background-color: #4B6587;
        text-align: center;
        padding: 10rem 25rem 10rem 25rem;
        h1{
            color: white;
            font-size: 35px;
        }
    }
    @media only screen and (max-width: 1150px){
        .login-container{
            padding: 1rem;
        }
    }
  .v-subheader{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }
</style>