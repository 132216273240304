<template>
  <v-card
    class="mx-auto overflow-hidden"
    height="50"
  >
    <v-app-bar
      dark
      app
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Admin Panel</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="../assets/avatar_admin.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-title style="color:white;">Administrator</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="border"
        >
          <router-link v-for="route in routes" :key="route.path" :to="route.path" tag="p">
            <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{route.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{route.name}}</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-5">
          <v-btn block class="primary-btn" @click="logout">
            Logout
          </v-btn>
        </div>
      </template>

    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: 'Bars',
  data: () => ({
    drawer: false,
    group: 0,
    routes: [
              {path: '/kelola/pilihan', name: 'Kelola Pilihan', icon: 'mdi-file-question-outline', active: true},
              {path: '/kelola/konten', name: 'Kelola Konten', icon: 'mdi-content-save-cog-outline', active: false},
              {path: '/ubah/password', name: 'Ubah Password', icon: 'mdi-key-variant', active: false},
            ]
  }),
  methods:{
    async logout(){
      const url = this.$api + '/admin/logout'
      const res = await this.$http.post(url, {
      }, { withCredentials: true })
        if(!res){
          return
        }else{
          sessionStorage.removeItem('isLoggedIn')
          this.$router.replace('/admin/login')
        }
      
    }
  }
};
</script>
<style scoped lang="scss">
  .v-navigation-drawer{
    background-color: #4B6587 !important;
  }
  .v-list .v-list-item-group .v-list-item{
    color: white;
    .v-icon{
      color: white;
    }
  }
  .border {
    border-left: 4px solid #d9ed92;
  }

</style>