<template>
    <div>
      <div class="banner-container">
        <div class="inner-banner">
          <div class="photo">
            <img src="../assets/gambar_banner.png">
          </div>
          <div class="content">
            <h2>{{banner.judul}} </h2>
            <p> {{banner.isi}} </p>
            <v-btn
              to="/tes/formulir"
              class="primary-btn"
            >Mulai Sekarang</v-btn>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="article">
          <h2>
            {{konten.judul}}
          </h2>
          <p>
          {{konten.isi_konten}}
        </p>
        </div>

        <div class="search-area">
          <h2>
            Pencarian Hasil Tes
          </h2>

          <v-form>
            <v-row>
              <v-col cols="12" md="4">
                <v-subheader>Email</v-subheader>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  label="Email"
                  single-line
                  v-model="email"
                  dense
                  outlined
                  solo
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn class="primary-btn" @click="findHasilByEmail()">
              Cari
            </v-btn>
          </v-form>

        </div>
      </div>

    </div>
</template>

<script>
  export default {
    name: 'Home',
    data(){
      return{
        daftarPilihan: [],
        konten:{
          judul: '',
          isi_konten: ''
        },
        banner:{
          judul: 'Kenali Diri Anda',
          isi: 'Temukan potensi dan bakat diri anda melalui metode tes Strength Typology 30'
        },
        email: ''
      }
    },
    methods:{
      async getKonten(){
        const url = this.$api + '/konten/tampil'
        const res = await this.$http.get(url, {
          withCredentials: true
        })
        this.konten = res.data.data
      },
      findHasilByEmail(){
        if(this.email != ''){
          this.$router.push({
            path: `/tes/cari/${this.email}`
          })
        }
      },
      deleteStorage(){
        sessionStorage.removeItem('DetailPilihanPalingCocok')
        sessionStorage.removeItem('DetailPilihanPalingTidakCocok')
        sessionStorage.removeItem('DetailPilihanCocok')
        sessionStorage.removeItem('DetailPilihanTidakCocok')
        sessionStorage.removeItem('isLoggedIn')
        sessionStorage.removeItem('id_tes')
        sessionStorage.removeItem('peserta')
        sessionStorage.removeItem('isDone')
      },
    },
    components: {
      
    },
    mounted(){
      this.getKonten()
      this.deleteStorage()
    }
  }
</script>
<style lang="scss">
  .v-subheader{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }
</style>
