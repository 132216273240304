<template>
    <div>
        <Navigation/>
        <main>
            <router-view></router-view>
        </main>
        <Footer/>
    </div>
</template>
<script>
import Footer from './Footer.vue'
import Navigation from './Navigation.vue'
export default {
    data(){
        return{

        }
    },
    components:{
        Navigation, Footer
    }
}
</script>