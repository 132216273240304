<template>
  <div class="container">
    <h1>Data Diri</h1>
    <div class="form-wrapper">
      <v-form
        v-model="valid"
        ref="form"
      >
        <v-row>
          <v-col cols="12" md="4" class="mb-0 pa-0">
            <v-subheader>
              Nama Lengkap
            </v-subheader>
          </v-col>

          <v-col
            cols="12" md="8" class="mt-0 pt-0"
          >
            <v-text-field
              label="Nama Lengkap"
              single-line solo dense outlined flat
              :rules="nameRules"
              v-model="form.nama"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" class="mb-0 pa-0">
            <v-subheader>
              Email
            </v-subheader>
          </v-col>

          <v-col cols="12" md="8" class="mt-0 pt-0">
            <v-text-field
              label="Email" single-line solo dense outlined flat
              :rules="emailRules"
              v-model="form.email"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" class="mb-0 pa-0">
            <v-subheader>
              Jenis Kelamin
            </v-subheader>
          </v-col>
          <v-col cols="12" md="8" class="mt-0 pt-3">
            <v-radio-group 
              row class="mt-0 pt-0" 
              v-model="form.jenis_kelamin"
              :rules="jenis_kelaminRules"
            >
              <v-radio
                label="Perempuan"
                value="Perempuan"
              ></v-radio>
              <v-radio
                label="Laki-laki"
                value="Laki-laki"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" class="mb-0 pa-0">
            <v-subheader>Tanggal Lahir</v-subheader>
          </v-col>

          <v-col cols="12" md="8" class="mt-0 pt-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.tgl_lahir"
                  label="Tanggal Lahir: hari-bulan-tahun"
                  persistent-hint
                  single-line solo dense outlined flat
                  v-bind="attrs"
                  @blur="date = parseDate(form.tgl_lahir)"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                show-current="2010-01-01"
                min="1960-01-01"
                max="2010-31-12"
                no-title
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" class="mb-0 pa-0">
            <v-subheader>Pekerjaan</v-subheader>
          </v-col>
          <v-col cols="12" md="8" class="mt-0 pt-0">
            <v-text-field
              label="Pekerjaan" single-line solo dense outlined flat
              :rules="pekerjaanRules"
              v-model="form.pekerjaan"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" class="mb-0 pa-0">
            <v-subheader>Organisasi</v-subheader>
          </v-col>
          <v-col cols="12" md="8" class="mt-0 pt-0">
            <v-text-field
              label="Organisasi" single-line solo dense outlined flat
              :rules="organisasiRules"
              v-model="form.organisasi"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" class="mb-0"> 
            <v-alert
                v-model="alertBox"
                dense outlined type="error"
            >
              {{ message }}
            </v-alert>
            <v-btn
              @click="validate"
              class="primary-btn"
            >
              Lanjut
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data(){
      return{
        menu: false,
        valid: false,
        tgl_lahir: null,
        alertBox: false,
        peserta: {
          nama: '',
          email: '',
          jenis_kelamin: '',
          tgl_lahir: '',
          pekerjaan: '',
          organisasi: ''
        },
        date: '2010-01-01',
        message: '',
        form:{
          nama: '',
          email: '',
          jenis_kelamin: '',
          tgl_lahir: this.formatDate(this.date),
          pekerjaan: '',
          organisasi: ''
        },
        nameRules: [
          v => !!v || 'Nama lengkap harap diisi',
          v => v.length <= 50 || 'Nama lengkap tidak boleh lebih dari 50 karakter, harap menggunakan singkatan.'
        ],
        emailRules:[
          v => !!v || 'Email harus diisi',
          v => /.+@.+\..+/.test(v) || 'Harap diisi dengan email yang valid', 
        ],
        tgl_lahirRules:[
          v => !!v || 'Tanggal lahir harus diisi'
        ],
        pekerjaanRules:[
          v => !!v || 'Pekerjaan harap diisi',
          v => v.length <= 20 || 'Pekerjaan tidak boleh lebih dari 20 karakter, harap menggunakan singkatan'
        ],
        organisasiRules:[
          v => !!v || 'Organisasi harap diisi',
          v => v.length <= 30 || 'Nama organisasi tidak boleh lebih dari 30 karakter, harap menggunakan singkatan'
        ],
        jenis_kelaminRules:[
          v => !!v || 'Harap memilih jenis'
        ]
      }
    },
    components: {

    },
    methods:{
      async validate(){
        const res = await this.$refs.form.validate()

        if(res){
          this.appendPeserta()
        }
      },
      async checkEmail(){
        let url = this.$api + '/peserta/checkemail'

        await this.$http.post(url, this.peserta, {
          withCredentials: true
        }).then(response => {
          this.alertBox = false,
          this.savePesertaToSession()
          console.log(response)
        }).catch(error => {
          this.message = error.response.data.message
          this.alertBox = true
          console.log('message: '+this.message)
        })

      },
      async appendPeserta(){
        this.peserta.nama = this.form.nama
        this.peserta.email = this.form.email
        this.peserta.jenis_kelamin = this.form.jenis_kelamin
        this.peserta.tgl_lahir = this.date
        this.peserta.pekerjaan = this.form.pekerjaan
        this.peserta.organisasi = this.form.organisasi

        this.checkEmail()
      },
      deleteStorage(){
        sessionStorage.removeItem('DetailPilihanPalingCocok')
        sessionStorage.removeItem('DetailPilihanPalingTidakCocok')
        sessionStorage.removeItem('DetailPilihanCocok')
        sessionStorage.removeItem('DetailPilihanTidakCocok')
        sessionStorage.removeItem('isLoggedIn')
        sessionStorage.removeItem('id_tes')
        sessionStorage.removeItem('peserta')
        sessionStorage.removeItem('isDone')
      },
      savePesertaToSession(){
          sessionStorage.setItem('isLoggedIn', true)
          //save data peserta ke sessionStorage
          sessionStorage['peserta'] = JSON.stringify(this.peserta)
          this.$router.push({
            name: 'Paling Cocok'
          })
      },
      formatDate(date){
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate(date){
        if (!date) return null

        const [month, day, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
    computed:{
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    watch:{
      date(){
        this.form.tgl_lahir = this.formatDate(this.date)
      }
    },
    mounted(){
      this.deleteStorage()
    }
  }
</script>
<style scoped>
  .v-subheader{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }

</style>
